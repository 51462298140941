import "./pastProjectDetail.css";
import ReactPlayer from "react-player";

import ArmutluVistaMainImg from "../../assets/projectImages/armutluVista/ArmutluVistaMain.jpg";
import ArmutluVistaImg1 from "../../assets/projectImages/armutluVista/ArmutluVista-1.jpg";
import ArmutluVistaImg2 from "../../assets/projectImages/armutluVista/Armutlu Vista - 2.jpg";
import ArmutluVistaImg3 from "../../assets/projectImages/armutluVista/Armutlu Vista - 3.jpg";
import ArmutluVistaImg4 from "../../assets/projectImages/armutluVista/Armutlu Vista - 4.jpg";

import KoruArmutluMainImg from "../../assets/projectImages/koruArmutlu/KoruArmutluMain.jpg";
import KoruArmutluImg1 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-1.jpg";
import KoruArmutluImg2 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-2.jpg";
import KoruArmutluImg3 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-3.jpg";
import KoruArmutluImg4 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-4.jpg";
import KoruArmutluImg5 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-5.jpg";
import KoruArmutluImg6 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-6.jpg";
import KoruArmutluImg7 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-7.jpg";
import KoruArmutluImg8 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-8.jpg";
import KoruArmutluImg9 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-9.jpg";
import KoruArmutluImg10 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-10.jpg";
import KoruArmutluImg11 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-11.jpg";
import KoruArmutluImg12 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-12.jpg";
import KoruArmutluImg13 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-13.jpg";
import KoruArmutluImg14 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-14.jpg";
import KoruArmutluImg15 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-15.jpg";
import KoruArmutluImg16 from "../../assets/projectImages/koruArmutlu/KoruArmutlu-16.jpg";
import KoruArmutluVideo from "../../assets/projectImages/koruArmutlu/whatsup.mp4";

import DereagziMainImg from "../../assets/projectImages/dereagzi/DereagziMain.jpg";
import DereagziImg1 from "../../assets/projectImages/dereagzi/Dereagzi-1.jpg";

import CengelkoyMımozaMainImg from "../../assets/projectImages/cengelkoyMimoza/CengelkoyMimozaMain.jpg";

import headerImg from "../../assets/bg-img-1.jpg";

import { useState } from "react";

const PastProjectDetail = () => {
  <link rel="stylesheet" href="./pastProjectDetail.css" />;

  const imagesArmutluVista = [
    { img: ArmutluVistaImg1 },
    { img: ArmutluVistaMainImg },
    { img: ArmutluVistaImg2 },
    { img: ArmutluVistaImg3 },
    { img: ArmutluVistaImg4 }
  ];

  const imagesKoruArmutlu = [
    { img: KoruArmutluMainImg },
    { img: KoruArmutluImg1 },
    { img: KoruArmutluImg2 },
    { img: KoruArmutluImg3 },
    { img: KoruArmutluImg4 },
    { img: KoruArmutluImg5 },
    { img: KoruArmutluImg6 },
    { img: KoruArmutluImg7 },
    { img: KoruArmutluImg8 },
    { img: KoruArmutluImg9 },
    { img: KoruArmutluImg10 },
    { img: KoruArmutluImg11 },
    { img: KoruArmutluImg12 },
    { img: KoruArmutluImg13 },
    { img: KoruArmutluImg14 },
    { img: KoruArmutluImg15 },
    { img: KoruArmutluImg16 },
  ];

  const imagesDereagzi = [{ img: DereagziMainImg }, { img: DereagziImg1 }];

  const imagesCengelkoyMimoza = [{ img: CengelkoyMımozaMainImg }];

  const [currentArmutluVista, setCurrentArmutluVista] = useState(0);
  const [currentKoruArmutlu, setCurrentKoruArmutlu] = useState(0);
  const [currentDereagzi, setCurrentDereagzi] = useState(0);
  const [currentCengelkoyMimoza, setCurrentCengelkoyMimoza] = useState(0);
  let dataLengthArmutluVista = imagesArmutluVista.length;
  let dataLengthKoruArmutlu = imagesKoruArmutlu.length;
  let dataLengthDereagzi = imagesDereagzi.length;
  let dataLenthCengelkoyMimoza = imagesCengelkoyMimoza.length;

  const currentDivArmutluVista = (n) => {
    setCurrentArmutluVista(n);
  };

  const currentDivKoruArmutlu = (n) => {
    setCurrentKoruArmutlu(n);
  };

  const currentDivDereagzi = (n) => {
    setCurrentDereagzi(n);
  };

  const currentDivCengelkoyMimoza = (n) => {
    setCurrentCengelkoyMimoza(n);
  };

  if (!Array.isArray(imagesArmutluVista) || imagesArmutluVista.length <= 0) {
    return null;
  }

  const nextSlideArmutluVista = () => {
    setCurrentArmutluVista(
      currentArmutluVista === dataLengthArmutluVista - 1
        ? 0
        : currentArmutluVista + 1
    );
  };

  const prevSlideArmutluVista = () => {
    setCurrentArmutluVista(
      currentArmutluVista === 0
        ? dataLengthArmutluVista - 1
        : currentArmutluVista - 1
    );
  };

  const nextSlideKoruArmutlu = () => {
    setCurrentKoruArmutlu(
      currentKoruArmutlu === dataLengthKoruArmutlu - 1
        ? 0
        : currentKoruArmutlu + 1
    );
  };

  const prevSlideKoruArmutlu = () => {
    setCurrentKoruArmutlu(
      currentKoruArmutlu === 0
        ? dataLengthKoruArmutlu - 1
        : currentKoruArmutlu - 1
    );
  };

  const nextSlideDereagzi = () => {
    setCurrentDereagzi(
      currentDereagzi === dataLengthDereagzi - 1 ? 0 : currentDereagzi + 1
    );
  };

  const prevSlideDereagzi = () => {
    setCurrentDereagzi(
      currentDereagzi === 0 ? dataLengthDereagzi - 1 : currentDereagzi - 1
    );
  };

  const nextSlideCengelkoyMimoza = () => {
    setCurrentCengelkoyMimoza(
      currentCengelkoyMimoza === dataLenthCengelkoyMimoza - 1
        ? 0
        : currentCengelkoyMimoza + 1
    );
  };

  const prevSlideCengelkoyMimoza = () => {
    setCurrentCengelkoyMimoza(
      currentCengelkoyMimoza === 0
        ? dataLenthCengelkoyMimoza - 1
        : currentCengelkoyMimoza - 1
    );
  };

  return (
    <div>
      <div className="container project-detail-container">
        <div className="bread-list" id="#header">
          <hr />
          <ul>
            <li className="return-mainpage">
              <a href="../#header">ANASAYFA</a>
            </li>

            <li>|</li>

            <li>Projeler</li>
          </ul>
          <hr />
        </div>

        <div className="project-list">
          <ul>
            <li>
              <div className="img-slide">
                {imagesArmutluVista.map((slide, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index === currentArmutluVista
                          ? "my-slide active"
                          : "my-slide"
                      }
                    >
                      <img src={slide.img} alt="" />
                    </div>
                  );
                })}
                <a className="prev" onClick={prevSlideArmutluVista}>
                  ❮
                </a>
                <a className="next" onClick={nextSlideArmutluVista}>
                  ❯
                </a>

                <div className="row">
                  {imagesArmutluVista.map((slide, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          index === currentArmutluVista
                            ? "col active-opacity"
                            : "col"
                        }
                      >
                        <img
                          onClick={() => currentDivArmutluVista(index)}
                          className="slide-images"
                          src={slide.img}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="project-details">
                <div className="header-background">
                  <img
                    className="background-image"
                    src={headerImg}
                    alt="headerImg"
                  />
                  <div className="header-content">
                    <h3>ARMUTLU VİSTA</h3>
                  </div>
                </div>
                <hr />
                <div className="project-details-rows">
                  <div className="project-details-left">
                    <h5>Proje Konumu</h5>
                    <p>Bayıt Mahallesi, Armutlu, Yalova</p>
                    <hr />
                    <h5>Proje Başlangıç-Bitiş Tarihi</h5>
                    <p>2016-2018</p>
                    <hr />
                    <h5>Daire Sayısı</h5>
                    <p>42</p>
                    <hr className="responsive-hr" />
                  </div>
                  <div className="project-details-right">
                    <h5>Blok Sayısı</h5>
                    <p>3</p>
                    <hr />
                    <h5>Arsa Büyüklüğü</h5>
                    <p>2570 m²</p>
                    <hr />
                    <h5>Kapalı Alan Büyüklüğü</h5>
                    <p>6500 m²</p>
                  </div>
                </div>
              </div>
            </li>
            <hr className="list-hr" />

            <li>
              <div className="img-slide">
                {imagesKoruArmutlu.map((slide, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index === currentKoruArmutlu
                          ? "my-slide active"
                          : "my-slide"
                      }
                    >
                      <img src={slide.img} alt="" />
                    </div>
                  );
                })}
                <a className="prev" onClick={prevSlideKoruArmutlu}>
                  ❮
                </a>
                <a className="next" onClick={nextSlideKoruArmutlu}>
                  ❯
                </a>

                <div className="row">
                  {imagesKoruArmutlu.map((slide, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          index === currentKoruArmutlu
                            ? "col active-opacity"
                            : "col"
                        }
                      >
                        <img
                          onClick={() => currentDivKoruArmutlu(index)}
                          className="slide-images"
                          src={slide.img}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="project-details">
                <div className="header-background">
                  <img
                    className="background-image"
                    src={headerImg}
                    alt="headerImg"
                  />
                  <div className="header-content">
                    <h3>KORU ARMUTLU</h3>
                  </div>
                </div>
                <hr />
                <div className="project-details-rows">
                  <div className="project-details-left">
                    <h5>Proje Konumu</h5>
                    <p>Bayıt Mahallesi, Armutlu, Yalova</p>
                    <hr />
                    <h5>Proje Başlangıç-Bitiş Tarihi</h5>
                    <p>2018-2021</p>
                    <hr />
                    <h5>Daire Sayısı</h5>
                    <p>52 + 2 Dükkan</p>
                    <hr className="responsive-hr" />
                  </div>
                  <div className="project-details-right">
                    <h5>Blok Sayısı</h5>
                    <p>3</p>
                    <hr />
                    <h5>Arsa Büyüklüğü</h5>
                    <p>2100 m²</p>
                    <hr />
                    <h5>Kapalı Alan Büyüklüğü</h5>
                    <p>7500 m²</p>
                  </div>
                </div>
              </div>
            </li>
            <hr className="list-hr" />

            <li>
              <div className="img-slide">
                {imagesDereagzi.map((slide, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index === currentDereagzi
                          ? "my-slide active"
                          : "my-slide"
                      }
                    >
                      <img src={slide.img} alt="" />
                    </div>
                  );
                })}
                <a className="prev" onClick={prevSlideDereagzi}>
                  ❮
                </a>
                <a className="next" onClick={nextSlideDereagzi}>
                  ❯
                </a>
                <div className="row">
                  {imagesDereagzi.map((slide, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          index === currentDereagzi
                            ? "col active-opacity"
                            : "col"
                        }
                      >
                        <img
                          onClick={() => currentDivDereagzi(index)}
                          className="slide-images"
                          src={slide.img}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="project-details">
                <div className="header-background">
                  <img
                    className="background-image"
                    src={headerImg}
                    alt="headerImg"
                  />
                  <div className="header-content">
                    <h3>DEREAĞZI</h3>
                  </div>
                </div>
                <hr />
                <div className="project-details-rows">
                  <div className="project-details-left">
                    <h5>Proje Konumu</h5>
                    <p>Karşıyaka Mahallesi, Armutlu, Yalova</p>
                    <hr />
                    <h5>Proje Başlangıç-Bitiş Tarihi</h5>
                    <p>2018-2021</p>
                    <hr />
                    <h5>Daire Sayısı</h5>
                    <p>12 + 1 Dükkan</p>
                    <hr className="responsive-hr" />
                  </div>
                  <div className="project-details-right">
                    <h5>Blok Sayısı</h5>
                    <p>1</p>
                    <hr />
                    <h5>Arsa Büyüklüğü</h5>
                    <p>710 m²</p>
                    <hr />
                    <h5>Kapalı Alan Büyüklüğü</h5>
                    <p>2000 m²</p>
                  </div>
                </div>
              </div>
            </li>
            <hr className="list-hr" />

            <li>
              <div className="img-slide">
                {imagesCengelkoyMimoza.map((slide, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index === currentCengelkoyMimoza
                          ? "my-slide active"
                          : "my-slide"
                      }
                    >
                      <img src={slide.img} alt="" />
                    </div>
                  );
                })}
                <a className="prev" onClick={prevSlideCengelkoyMimoza}>
                  ❮
                </a>
                <a className="next" onClick={nextSlideCengelkoyMimoza}>
                  ❯
                </a>
                <div className="row">
                  {imagesCengelkoyMimoza.map((slide, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          index === currentCengelkoyMimoza
                            ? "col active-opacity"
                            : "col"
                        }
                      >
                        <img
                          onClick={() => currentDivCengelkoyMimoza(index)}
                          className="slide-images"
                          src={slide.img}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="project-details">
                <div className="header-background">
                  <img
                    className="background-image"
                    src={headerImg}
                    alt="headerImg"
                  />
                  <div className="header-content">
                    <h3>ÇENGELKÖY MİMOZA APARTMANI</h3>
                  </div>
                </div>
                <hr />
                <div className="project-details-rows">
                  <div className="project-details-left">
                    <h5>Proje Konumu</h5>
                    <p>Güzeltepe Mahallesi, Üsküdar, İstanbul</p>
                    <hr />
                    <h5>Proje Başlangıç-Bitiş Tarihi</h5>
                    <p>2020-2022</p>
                    <hr />
                    <h5>Daire Sayısı</h5>
                    <p>10</p>
                    <hr className="responsive-hr" />
                  </div>
                  <div className="project-details-right">
                    <h5>Blok Sayısı</h5>
                    <p>1</p>
                    <hr />
                    <h5>Arsa Büyüklüğü</h5>
                    <p>830 m²</p>
                    <hr />
                    <h5>Kapalı Alan Büyüklüğü</h5>
                    <p>1200 m²</p>
                  </div>
                </div>
              </div>
            </li>
            <hr className="list-hr-last" />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PastProjectDetail;
