import BoM from "../../../sections/BoM/BoM";
import "../dashboard.css"

const BoMDashboard = () => {
  return (
    <div>
      <div className="container kurumsal-container">
        <div className="bread-list">
          <hr />
          <ul>
            <li className="return-mainpage">
              <a href="../#header">ANASAYFA</a>
            </li>

            <li>|</li>

            <li>Yönetim Kurulu</li>
          </ul>
          <hr />
        </div>
      </div>
      <BoM />
    </div>
  );
};

export default BoMDashboard;
