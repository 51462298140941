import Misvis from "../../../sections/mis-vis/Misvis";
import "../dashboard.css"

const MisvisDashboard = () => {
  return (
    <div>
      <div className="container kurumsal-container">
        <div className="bread-list">
          <hr />
          <ul>
            <li className="return-mainpage">
              <a href="../#header">ANASAYFA</a>
            </li>

            <li>|</li>

            <li>Misyonumuz ve Vizyonumuz</li>
          </ul>
          <hr />
        </div>
      </div>
      <Misvis />
    </div>
  );
};

export default MisvisDashboard;
