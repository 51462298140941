import Projects from "../sections/projects/Projects";

const Mainpage = () => {
  return (
    <div>
      <Projects />
    </div>
  );
};

export default Mainpage;
