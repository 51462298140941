import "./about.css";
import logo from "../../assets/logo.png";

const About = () => {
  return (
    <section id="about">
      <div className="container about-container">
        <div className="col-container left">
          <a href="#header">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="col-container right">
          <h2>Hakkımızda</h2>
          <hr />
          <h3>YOU YAPI MİMARLIK SAN. VE TİC. LTD. STİ.</h3>
          <p>
              You Yapı olarak verdiğimiz taahhütleri tam zamanında yerine getiren
            ve müşterilerimize kaliteli bir konut hizmeti sunma gayesi ile
            hizmete başlayan bir inşaat firmasıyız. Seçkin konut projelerimiz
            sayesinde müşterilerimize sağlıklı bir yaşam alanı kurmayı kendimize
            görev edindiğimiz firmamız Yasin FINDIK tarafından kuruldu.
          </p>
          <p>
              You Yapı firmamız sektörde başta Yalova ve İstanbul olmak üzere
            konut inşaatı alanında faaliyet göstermektedir. Günümüzün konut
            ihtiyaçlarını karşılamak için birçok proje ile müşterilerimize
            kaliteli bir yaşam alanı hazırlıyoruz. You Yapı sahip olduğu kadro
            ile geleceğin güvenilir ve deneyimi yüksek firmaları arasında
            anılmayı hedeflemektedir. İstanbul ve Yalova illeri için konut
            sektöründe ciddi projelerimiz ile konut ihtiyacına olan yoğun talebi
            karşılıyoruz.
          </p>
          <p>
              Firmamızın bugüne kadar tamamladığı 4 büyük konut projesi
            bulunmaktadır. 3 blok 42 daireden oluşan Armutlu Vista projesi ve 3
            blok 56 daire 2 işyerinden oluşan Koru Armutlu, 12 daire 1
            işyerinden oluşan Dereağzı projesi ve 10 daireden oluşan Çengelköy
            Çolak apartmanı projesi bugüne kadar tamamlayıp hizmete sunduğumuz
            projelerimizdir. Projelerde her zaman açıklık ve dürüstlük
            ilkelerine önem veren firmamız, bu konuya küçük veya büyük tüm
            çalışmalarda dikkat etmektedir.
          </p>
          <p>
              You Yapı firmamız kuruluşundan itibaren seçkin konut projelerine
            hızla başlangıç yapmıştır. Yaptığımız projelerde zaman konusunda
            müşterilerimize verdiğimiz sözü önemsemekteyiz. Gelecek projeler
            için zaman yönetimini doğru planlayarak güven ilişkimizi büyük bir
            önem ile sürdürmeyi hedefliyoruz. Müşterilerimize zamanında anahtar
            teslimi yaparak evlerine eksiksiz bir şekilde yerleştirmek için
            büyük bir özveri ve şeffaflık içerisinde çalışıyoruz. Günümüzün
            konut projelerine uygun bir şekilde, teknolojik gelişmeleri de göz
            önüne alarak uygun yaşam alanlarını müşterilerimize sağlıyoruz.
            Müşterilerimize vaat ettiğimiz yaşam alanları için projelerimizde
            hayal ettikleri konforu en güvenli ve yenilikçi şekilde
            sağlamaktayız. Firmamızın sunduğu yaşam alanlarında müşterilerimizin
            hayallerine kavuşmaları için birçok fırsatı akılcı bir şekilde
            tasarımlarımıza yansıtmaktayız.
          </p>
          <p>
              You Yapı olarak projelerimizden konut alan müşterilerimiz ile ticari
            ilişkiden daha fazlasını hedefliyoruz. Ayrıca sürdürülebilir
            duygusal bir bağ ile tüm müşterilerimizle firmamızın stratejik ortak
            olmalarını öncelikli olarak amaçlıyoruz. Firma olarak her
            müşterimizi kendimize dolaylı bir avukat olarak benimsiyoruz. Bu
            sayede yapılan konut projelerimizin her aşamasında bizlerin
            güvenilir olarak yaptığımız işin arkasında olduğumuzu takip
            etmelerini istiyoruz. Firma olarak inşaat sektöründen alacağımız
            pazar payından ziyade müşterilerimizden alacağımız gönül payına
            talibiz.
          </p>
          <p>
              Firma olarak müşteri memnuniyetini esas alan gerçek bir hizmet
            anlayışını benimsemekteyiz. Bunu eksiksiz bir şekilde sağlamak için
            her çalışanımız ile özverili bir şekilde plan ve program
            yapmaktayız. Bu yolda her bir çalışanımız için de firmamıza karşı
            bir aidiyet beklemekteyiz. Çalışanlarımızın katkı taraflarını
            artırmayı ve aynı zamanda her birinden adanmışlık ortamı
            yaratılmasını istemekteyiz. Gerçek bir ekip çalışması ile ailelerin
            huzurla yaşayacağı ortamları inşa etmeyi hedefliyoruz.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
