import "./footer.css";
import logo from "../../assets/logo.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footer-container">
        <div className="footer-content">
          <div className="footer-logo">
            <a href="#header">
              <img src={logo} alt="logo" />
            </a>
            <div className="phone">
              <i>
                <BsFillTelephoneFill />
              </i>
              <h4> 0536 980 44 24 </h4>
            </div>
          </div>

          <div className="footer-fast-access">
            <h4>Hızlı Erişim</h4>
            <hr />
            <ul>
              <li>
                <a href="/#header">Anasayfa</a>
              </li>
              <li>
                <a href="/yonetimkurulu">Yönetim Kurulu</a>
              </li>
              <li>
                <a href="/hakkımızda">Hakkımızda</a>
              </li>
              <li>
                <a href="/misvis">Misyonumuz ve Vizyonumuz</a>
              </li>
              <li>
                <a href="/#contact">İletişim</a>
              </li>
            </ul>
          </div>

          <div className="footer-social">
            <h4>Sosyal Medya</h4>
            <hr />
            <p>Bizi takip etmeyi unutmayın!</p>
            <ul>
              <li className="instagram">
                <a href="">
                  <i>
                    <AiFillInstagram />
                  </i>
                </a>
              </li>
              <li className="facebook">
                <a href="">
                  <i>
                    <AiFillFacebook />
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          <span dangerouslySetInnerHTML={{ __html: "&copy;" }} /> 2023 You Yapı.
          Tüm hakları saklıdır.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
