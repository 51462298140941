import "./BoM.css";
import PersonelPhoto from "../../assets/Yasin Fındık Vesikalık.jpg";

const BoM = () => {
  return (
    <section id="BoM">
      <div className="container BoM-container">
        <div className="col left">
          <h1>Yasin FINDIK</h1>
          <p>
            Yasin Fındık, You Yapı kurucusu ve genel müdürüdür. 1986 yılında
            İstanbul Şişli’de doğan Fındık, İstanbul Atatürk Anadolu Lisesi’nde
            mezun olmasını takiben lisans eğitimini ise Sakarya Üniversitesi
            Makina Mühendisliği bölümünden mezun olarak tamamlamıştır. 2009
            yılında yüksek lisans eğitimi için Amerika Birleşik Devletleri’ne
            gitmiştir. Massachusetts eyaletinde Cambridge College’da MBA yaparak
            2013 yılında Türkiye’ye dönüş yapmıştır. 2016 yılında You Yapı’yı
            kurarak inşaat sektöründe ticaret hayatına başlamış ve halihazırda
            genel müdürlük görevini sürdürmektedir.
          </p>
        </div>
        <div className="col right">
          <img src={PersonelPhoto} alt="Yasin Fındık" />
        </div>
      </div>
    </section>
  );
};

export default BoM;
