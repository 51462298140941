import "./projects.css";
import img1 from "../../assets/img1.jpg";
import img2 from "../../assets/img2.jpg";
import img3 from "../../assets/img3.jpg";
import logo from "../../assets/logo.png";
import { useState } from "react";

const Projects = () => {
  const imagesData = [{ img: img1 }, { img: img2 }, { img: img3 }];
  const headers = [
    "ÖZGÜN PROJELER İLE SİZLERLE BİRLİKTEYİZ",
    "KALİTEYİ SİZLERLE BULUŞTURMAK İÇİN ÇALIŞIYORUZ",
    "MODERN VE KALİTELİ YAŞAM ALANLARI SUNUYORUZ",
  ];
  const contests = [
    "Sizleri yenilikçi, özgün, modern ve şehre uygun projelerimizle buluşturuyoruz.",
    "Projelerimizde sizlere kaliteli yaşam alanları sunabilmek için çalışıyoruz.",
    "Modernize edilmiş en kaliteli projelerimiz sizlerle birlikte.",
  ];
  const [current, setCurrent] = useState(0);
  let dataLength = imagesData.length;

  if (!Array.isArray(imagesData) || imagesData.length <= 0) {
    return null;
  }

  const nextSlide = () => {
    setCurrent(current === dataLength - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? dataLength - 1 : current - 1);
  };
  return (
    <section id="projects">
      <div className="container projects-container">
        <div className="head-row">
          <div className="head-col left">
            <h1>YOU YAPI MİMARLIK</h1>
          </div>
          <div className="head-col right">
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="projects-row">
          <div className="slide-show-container">
            {imagesData.map((slide, index) => {
              
              setTimeout(nextSlide, 4500);
              return (
                <div
                  key={index}
                  className={
                    index === current
                      ? "mySlides activeSlide fade"
                      : "mySlides fade"
                  }
                >
                  <div className="text">
                    <h3 key={index}>{headers[index].toString()}</h3>
                    <p key={index}>{contests[index].toString()}</p>
                  </div>
                  <img src={slide.img} alt="project" />
                </div>
              );
            })}
            <a className="prev" onClick={prevSlide}>
              ❮
            </a>
            <a className="next" onClick={nextSlide}>
              ❯
            </a>
            <div className="detail-button">
              <a href="/pastproductdetail#header">
                <button>Projelerimizi İnceleyin → </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
