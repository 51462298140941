import "./misvis.css";

const Misvis = () => {
  return (
    <section id="misvis">
      <div className="container misvis-container">
        <div className="misvis">
          <div className="col-container left">
            <h2>Misyonumuz</h2>
            <hr />
            <p>
              Yapı inşası sunarken güven ve aynı zamanda bir gönül bağı inşa
              etmek; açık, şeffaf ve dürüstlük politikası izlemek,
              müşterilerimize her zaman modern ve estetik yapılar sunarak yaşam
              alanlarına değer katmaktır.
            </p>
          </div>
          <div className="col-container right">
            <h2>Vizyonumuz</h2>
            <hr />
            <p>
              Müşterilerimize farklı tasarımlar ile hem estetik hem de
              teknolojiye uygun konutlar sunarken, memnuniyet ve kaliteyi ön
              planda tutarak sektörün ihtiyaçlarını en iyi şekilde
              karşılamaktır.
            </p>
          </div>
        </div>
        <div className="amac">
          <h2>Amaç ve Değerlerimiz</h2>
          <hr />
          <ul>
            <li>
              <p>
                You Yapı olarak ürünlerimizde kaliteyi anahtar kelime
                olarak benimsiyoruz. Sektörde kalite denilince akla gelen marka
                olmayı amaçlıyoruz. Yaptığımız konutların kalitesi konusunda tüm
                müşterilerimize açıklık ve şeffaflık ışığında taahhüt
                vermekteyiz.
              </p>
            </li>
            <li>
              <p>
                Ürünlerimizi satarken değil sonrasında da bir gönül bağı
                amaçladığımızdan, her an satış sonrası destek ve servis
                sunuyoruz. Yaptığımız projelerin ve satışların sonrasında da
                müşterilerimize güven ve saygı çerçevesinde hizmet vermeyi
                önemsiyoruz.
              </p>
            </li>
            <li>
              <p>
                You Yapı olarak eylem tutarlığını amaç edinerek, müşterilerimize
                konut için söz verdiğimiz zamanda anahtar teslimi yaparak
                imajımızı doğru yansıtmayı önemiyoruz.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Misvis;
