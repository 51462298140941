import "./navbar.css";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import {IoMdArrowDropdown} from "react-icons/io"
import logo from "../../assets/logo.png"

const NavBar = () => {
  return (
    <nav>
      <div className="container nav-container">
        <div className="nav-logo">
          <a href="../#header">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div className="nav-links">
          <ul>
            <li>
              <a href="../#header">Anasayfa</a>
            </li>
            <li>
                <a>Kurumsal<IoMdArrowDropdown></IoMdArrowDropdown></a>
                <ul className="menu-dropdown">
                <li><a href="/yonetimkurulu">Yönetim Kurulu</a></li>
                  <li><a href="/hakkımızda">Hakkımızda</a></li>
                  <li><a href="/misvis">Misyonumuz ve Vizyonumuz</a></li>                
                </ul>
            </li>
            <li>
              <a href="/pastproductdetail">Projelerimiz</a>
            </li>
            <li>
              <a href="#contact">İletişim</a>
            </li>
          </ul>
        </div>
        <div className="nav-social">
          <ul>
            <li className="instagram">
              <a href="">
                <i>
                  <AiFillInstagram />
                </i>
              </a>
            </li>
            <li className="facebook">
              <a href="">
                <i>
                  <AiFillFacebook />
                </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
