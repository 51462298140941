import "./contact.css";
import { BiSolidMap } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import alertify from "alertifyjs";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_m1o8mlm",
        "template_te7yn96",
        form.current,
        "jbgnp2X8FYcP3e7my"
      )
      .then(
        (result) => {
          console.log(result.text);
          alertify.success("İletiniz Başarıyla Gönderilmiştir.");
        },
        (error) => {
          console.log(error.text);
        }
      );
    event.target.reset();
  };

  return (
    <section id="contact">
      <div className="container contact-container">
        <div className="row">
          <div className="col-left">
            <h2> Bizimle İletişime Geçin</h2>
            <form ref={form} onSubmit={sendEmail}>
              <input
                className="form-group"
                required
                data-error="Lütfen adınızı giriniz."
                type="text"
                name="fullName"
                placeholder="Adınız Soyadınız"
              />
              <input
                className="form-group"
                required
                data-error="Lütfen e-mail adresinizi giriniz."
                type="email"
                name="email"
                placeholder="E-mail"
              />
              <input
                className="form-group"
                required
                data-error="Lütfen telefon numaranızı giriniz."
                type="text"
                name="phone"
                placeholder="Telefon"
              />
              <textarea
                className="form-group"
                required
                data-error="Lütfen mesajınızı yazınız."
                name="message"
                cols="30"
                rows="8"
              ></textarea>
              <button type="submit" value="submit" className="btn">
                Gönder
              </button>
            </form>
          </div>
          <div className="col-right">
            <h2>İletişim Bilgileri</h2>
            <h4>YOU YAPI MİMARLIK SAN. VE TİC. LTD. STİ.</h4>
            <ul>
              <li>
                <i>
                  <BiSolidMap />
                </i>
                <p>
                  Mehmet Akif Ersoy Mah. Hoşsohbet Cad. No:13/8 Kağıthane/İstanbul
                </p>
              </li>
              <li>
                <i>
                  <AiOutlineMail />
                </i>
                <p>info@youyapi.com</p>
              </li>
              <li>
                <i>
                  <BsFillTelephoneFill />
                </i>
                <p>0(536) 980 44 24</p>
              </li>
            </ul>
            <iframe
              title="Company-Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1504.1739956547697!2d28.952318029099263!3d41.061385404685105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab734a81107bf%3A0x568dde55c859cfe0!2zTWVobWV0IEFraWYgRXJzb3ksIEhvxZ9zb2hwZXQgQ2QuIE5vOjEzIEQ6OCwgMzQ0MDAgS8OixJ_EsXRoYW5lL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1690975502040!5m2!1str!2str"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
