import Mainpage from "./components/Mainpage";
import PastProjectDetail from "./components/pastProjectDetail/PastProjectDetail";
import { Route, Routes } from "react-router-dom";
import Footer from "./sections/footer/Footer";
import AboutDashboard from "./components/kurumsal/about/AboutDashboard";
import MisvisDashboard from "./components/kurumsal/mission/MisvisDashboard";
import BoMDashboard from "./components/kurumsal/BoM/BoMDashboard";
import Contact from "./sections/contact/Contact";
import NavBar from './sections/navbar/Navbar';

const App = () => {
  return (
    <main>
      <NavBar/>
      <Routes>      
        <Route path="/" element={<Mainpage />}></Route>
        <Route
          path="/pastproductdetail"
          element={<PastProjectDetail />}
        ></Route>
        <Route
          path="/hakkımızda"
          element={<AboutDashboard />}
        ></Route>
        <Route
          path="/misvis"
          element={<MisvisDashboard />}
        ></Route>
        <Route
          path="/yonetimkurulu"
          element={<BoMDashboard />}
        ></Route>
      </Routes>
      <Contact/>
      <Footer />
    </main>
  );
};

export default App;
